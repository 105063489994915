import React from 'react';
import Layout from '../layouts/index';
import Hero from '../components/hero/Hero';
import HeroIllustration from '../components/hero/HeroIllustration';

const IndexPage = () => {
  return (
    <Layout>
      <Hero
        title="Bringing Tech into Islam."
        content="Email us at info@deenful.com to find out more"
        illustration={HeroIllustration}
      />
    </Layout>
  )
}

export default IndexPage;
