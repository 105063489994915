import React, { Component } from 'react'
import { setupScrollReveal } from '../../assets/js/main.js';

// import NewsletterForm from '../forms/NewsletterForm';
import ST_ICON from './ST2 icon.png';
import A_ICON from './azkar 2.jpg'
import ISLA_ICON from './islamind_logo.png';

class Hero extends Component {
  componentDidMount() {
    setupScrollReveal();
  }

  render() {
    const { title, content } = this.props;
    const Illustration = this.props.illustration;

    return (
      <section className="hero">
        <div className="container">
          <div className="hero-inner">
            <div className="hero-copy">
              <div className="container-sm">
                <h1 className="hero-title h2-mobile mt-0 is-revealing">{title}</h1>
                <p className="hero-paragraph is-revealing"><a href="mailto:info@deenful.com" style={{textDecoration: 'none'}}>{content}</a></p>
              </div>

              {/* <NewsletterForm className="hero-form" submit="Get early access" /> */}
              <div>
                <a href="https://www.salahtimez.com" target="_blank">
                    <img src={ST_ICON} style={{
                        width: '70px',
                        marginRight: '18px',
                        marginBottom: '12px',
                        borderRadius: '50%',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)'
                    }} />
                </a>
               
                {/* <img src={A_ICON} style={{
                    width: '70px',
                    marginRight: '18px',
                    marginBottom: '12px',
                    borderRadius: '50%',
                    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)'
                }} />
                <img src={ISLA_ICON} style={{
                    height: '70px',
                    marginBottom: '12px',

                    width: 'auto',
                    borderRadius: '4px',
                    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.2)'
                }} /> */}
              </div>
            </div>

            <div className="hero-illustration">
              <Illustration />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Hero;
